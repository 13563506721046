import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
// --custom components--
import CatagoriesSkeleton from '../../components/CatagoriesSkeleton'
import { MyContext } from '../../MyContext'
import HelmetComponent from '../../components/HelmetComponent'
// --images--
import AsharaHomeUi from "../../asset/og/og-static-web-page.png";
import LightBulb from '../../asset/icons/light-bulb.png';

const LandingPageMap = () => {
  const { t } = useTranslation();
  const landingPagesImages = t('landing_pages_images', {returnObjects: true})
  const { isTabletMode, isLargeScreenMode } = useContext(MyContext);
  return (
    landingPagesImages.map(({src, title, url, hash}, index)=> (
      <CatagoriesSkeleton
        key={index}
        title={title}
        src={src}
        url={url}
        hash={hash}
        height={
          isTabletMode 
            ? 133 
          : isLargeScreenMode 
            ? 196
          : 165 }
      />
    ))
  )
}
const LandingPage = () => {
  const { t } = useTranslation();

  const metaTitle = "Static Web Pages - Kalid M."
  const metaDescription = "Simple and Clean Static Web pages created in HTML, CSS, and JavaScript."
  const baseUrl = window.location.origin;
  const currentPath = window.location.pathname
  const canonicalUrl = `${baseUrl}${currentPath}`
  const ogImage = AsharaHomeUi;
  const ogImageAlt = "static web pages"

  return (
    <div className="works-cont">
      <div className='catagories-note-cont'>
        <img className='note-light-bulb' width="20" height="20" src={LightBulb} alt="light bulb" /> 
        <p  className='catagories-note'> { t('note') } </p>
      </div>
      
      <div id="works" className="works">
        <HelmetComponent 
          metaTitle={metaTitle}
          metaDescription={metaDescription}
          canonicalUrl={canonicalUrl}
          ogImage={ogImage}
          ogImageAlt={ogImageAlt}
          />

        
        <LandingPageMap />
      </div>
    </div>
  )
}

export {LandingPage, LandingPageMap};