import React from 'react'
import { useTranslation } from 'react-i18next'
//--components--
import {UiDesignMap} from './UiDesign'
import {LandingPageMap} from './LandingPage'
import { ReactAppMap } from './ReactApp'
// --images--
import LightBulb from '../../asset/icons/light-bulb.png'

const All = () => {
  const { t } = useTranslation();

  return (
    <div className="works-cont">
      <div className='catagories-note-cont'>
        <img className='note-light-bulb' width="20" height="20" src={LightBulb} alt="light bulb" />   
        <p  className='catagories-note'> {t('note')} </p>
      </div>

      <div id="works" className="works">
        <UiDesignMap />
        <LandingPageMap />
        <ReactAppMap />
      </div> 
    </div>
  )
}

export default All