import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
import { Blurhash } from 'react-blurhash';
import { NavLink } from 'react-router-dom';

// import FacebookIcon from '../asset/icons/facebook.png'
// import LinkedinIcon from '../asset/icons/linkedin.png'
// import TelegramIcon from '../asset/icons/telegram.png'
// import GithubIcon from '../asset/icons/github.png'

import FacebookIcon from '../asset/icons/facebook-white.png'
import TelegramIcon from '../asset/icons/telegram-white.png'
import LinkedinIcon from '../asset/icons/linkedin-white.png'
import GithubIcon from '../asset/icons/github-white.png'
import Logo from '../asset/logo/logo-transparent.png';

const Footer = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  
  const handleImageLoad = () => {
    setIsLoaded(true)
  }

  const { t } = useTranslation();
  return (
    <footer className="footer-container">
      <div className="logo-and-social">
        <div className="logo-blur-cont">
          <div style={{display: isLoaded === false ? "inline" : "none"}}>
            <Blurhash
              hash="L8BDWq%20K9Z_Noft7R500R*_Nxu"
              width={"100%"}
              height={"100%"}
              resolutionX={32}
              resolutionY={32}  
              punch={1}  
            />
          </div>
          
          <NavLink to={"/"}>
            <img 
              className="kd-logo" 
              src={Logo} 
              alt="kalid's logo" 
              style={{display: isLoaded === true ? "block" : "none"}}
              onLoad={handleImageLoad}
            />
          </NavLink>
        </div>
        <div className="social-icons">
        <a 
            className="link-outside" 
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/in/kalid-mohammed-4aa747253"
          >
            <img className="icon" loading="lazy" src={LinkedinIcon} alt="linkedin" />
          </a>

          <a 
            className="link-outside" 
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/kalidmd"
          >
            <img className="icon" loading="lazy" src={GithubIcon} alt="github" />
          </a>
          
          <a 
            className="link-outside" 
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/profile.php?id=100061552866177"
          >
            <img className="icon" loading="lazy" src={FacebookIcon} alt="facebook" />
          </a>

          <a 
            className="link-outside" 
            target="_blank"
            rel="noopener noreferrer"
            href="https://t.me/Delahk"
          >
            <img className="icon" loading="lazy" src={TelegramIcon} alt="telegram" />
          </a>
        </div>
      </div>
      
      <hr />
      <p className="copyright"> {t('copyright')} </p>

    </footer>
  )
}

export default Footer