import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import HelmetComponent from '../components/HelmetComponent'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'

const PageNotFound = () => {
  const { t } = useTranslation();

  return (
    <div>
      <NavBar />
      <div className="page-not-found-cont">
          <HelmetComponent 
            metaTitle={"404"}
            />

        <h1>404!</h1>
        <p>{t('page_not_found')}</p>
        <div className="inline">
          <NavLink to={"/"}> {t('back')}&nbsp;</NavLink> 
          <p> {t('to_home')} </ p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default PageNotFound