import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import Language from './Language';

const NavBar = () => {
  const { t } = useTranslation()
 
   return (
        <div className="nav-container">
          <div className="nav-links">
            <NavLink 
              to={"/"}
            > 
              {t('home')} 
            </NavLink>

            <NavLink 
              to={"/works"}
            > 
              {t('works')} 
            </NavLink>

            <Language />
          </div>
        </div>
  )
}

export default NavBar