import React from 'react'
import { Helmet } from 'react-helmet-async'


const HelmetComponent = ({ metaTitle, metaDescription, canonicalUrl, ogImage, ogImageAlt }) => {
  return (
    <Helmet>
        <title> {metaTitle} </title>
        <meta name="description" content={metaDescription} />
        <link rel="canonical" href={canonicalUrl} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:site_name" content="Kalid Mohammed" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} /> 
        <meta property="og:image" content={ogImage} />
        <meta property="og:image:alt" content={ogImageAlt} />
        {/* <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" /> */}
        {/* <meta property="og:image:type" content="image/jpeg" /> */}
    </Helmet>
  )
}

export default HelmetComponent