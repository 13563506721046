import React from 'react'
import { useTranslation } from 'react-i18next'

import LinkedinIcon from '../asset/icons/linkedin-original.png'
import FacebookIcon from '../asset/icons/facebook-original.png'
import TelegramIcon from '../asset/icons/telegram-original.png'
import GithubIcon from '../asset/icons/github.png'

const Contact = () => {
  const { t } = useTranslation();

  return (
    <div className="contact-cont">
        <div className="contact-title-cont">
          <h1 className="contact-title"> {t('contact')} </h1>
          <div className="contact-underline"></div>
        </div>
        <div className="social-contact-cont">
          <a target="_blank" rel="noopener noreferrer" href="https://github.com/kalidmd">
            <div className="social-contact">
              <img loading="lazy" src={GithubIcon} alt="github" />
              <div className="social-item">
                <p className="social-item-header">{t('github')}</p>
                <p className="social-item-connect">{t('profile')} <span className="social-item-connect-arrow">→</span></p>
              </div>
            </div>
          </a>

          <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/kalid-mohammed-4aa747253">
            <div className="social-contact">
              <img loading="lazy" src={LinkedinIcon} alt="linkedin" />
              <div className="social-item">
                <p className="social-item-header"> {t('linkedin')} </p>
                <p className="social-item-connect"> {t('connect_link')} <span className="social-item-connect-arrow">→</span></p>
              </div>
            </div>
          </a>

          <a target="_blank" rel="noopener noreferrer" href="https://t.me/Delahk">
            <div className="social-contact">
              <img loading="lazy" src={TelegramIcon} alt="linkedin" />
              <div className="social-item">
                <p className="social-item-header">{t('telegram')}</p>
                <p className="social-item-connect">{t('chat_link')} <span className="social-item-connect-arrow">→</span></p>
              </div>
            </div>
          </a>

          <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/profile.php?id=100061552866177">
            <div className="social-contact">
              <img loading="lazy" src={FacebookIcon} alt="linkedin" />
              <div className="social-item">
                <p className="social-item-header">{t('facebook')}</p>
                <p className="social-item-connect">{t('connect_link')} <span className="social-item-connect-arrow">→</span></p>
              </div>
            </div>
          </a>

        </div> 
    </div>
  )
}

export default Contact