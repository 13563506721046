import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
// import { hydrate, render } from "react-dom";
import { RouterProvider } from 'react-router-dom';
import i18n from "i18next";
import {initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { HelmetProvider } from 'react-helmet-async';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import 'bootstrap-icons/font/bootstrap-icons.css';
// --styles--
import './index.css';
// --custom components--
import { router } from './router';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedlngs: ['en', 'am'],
    fallbackLng: "en",
    detection: {
        order: ['cookie', 'path', 'localStorage', 'htmlTag', 'subdomain'],
        caches: ['cookie'],
    },
    backend: {
        loadPath: '/assets/locales/{{lng}}/translation.json'
    }
  });

  const loading = (
    <div className="loading">
      <div className="loading-ring"></div>
    </div>
  )

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <HelmetProvider>
      <Suspense fallback={loading}>
        <RouterProvider router={router} />
      </Suspense>
    </HelmetProvider>
  );