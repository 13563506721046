import React, { useState } from "react";
// import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";

import EtFlag from "../asset/icons/et-flag.png"
import UsFlag from "../asset/icons/us-flag.png"

const Language = () => {
      const currentLanguageCode = cookies.get('i18next') || 'en'
      const currentBtn = currentLanguageCode === 'en' ? 'EN' : 'አማ'
      const [code, setCode] = useState(currentLanguageCode);
      const [btn, setBtn] = useState(currentBtn);
    
      const changeBtn = () => {
        const newCode = code === 'en' ? 'am' : 'en';
        const newBtn = btn === 'EN' ? 'አማ' : 'EN';
    
        setCode(newCode);
        setBtn(newBtn);
  
        i18next.changeLanguage(newCode);
      }
  return (
    <div>
        <div class="dropdown">
            <button class="btn  dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                { btn === "EN" ? 
                    <img className="dropdown-flag" src={EtFlag} alt="Ethiopian Flag" /> : 
                    <img className="dropdown-flag" src={UsFlag} alt="US Flag" />
                }
            </button>
            <ul class="dropdown-menu">
                <li>
                    <button 
                        disabled={currentLanguageCode === "am"} 
                        onClick={changeBtn} 
                        class="dropdown-item">
                            <img 
                                className="lang-flag" 
                                src={EtFlag} 
                                alt="Ethiopian Flag" 
                                style={{opacity: currentLanguageCode === "am" ? "0.7" : "1"}}
                            /> አማርኛ
                    </button>
                </li>
                <li>
                    <button 
                        disabled={currentLanguageCode === "en"} 
                        onClick={changeBtn} 
                        class="dropdown-item">
                           <img 
                            className="lang-flag" 
                            src={UsFlag} 
                            alt="US Flag" 
                            style={{opacity: currentLanguageCode === "en" ? "0.7" : "1"}}
                           /> English
                    </button>
                </li>
            </ul>
        </div>
    </div>
  )
}

export default Language