import React from 'react'
import { useTranslation } from 'react-i18next';
// --custom components--
import CatagoriesSkeleton from '../../components/CatagoriesSkeleton';
import HelmetComponent from '../../components/HelmetComponent';
// --images--
import TBWCommingSoon from "../../asset/og/og-ui-design.png";
import LightBulb from '../../asset/icons/light-bulb.png'

const UiDesignMap = () => {
  const { t } = useTranslation();
  const uiDesignImages = t('ui_design_images', {returnObjects: true})
  return (
    uiDesignImages.map(({title, src, url, hash}, index)=> (
      <CatagoriesSkeleton
        key={index}
        title={title}
        src={src}
        url={url}
        hash={hash}
      />
    ))
  )
}

const UiDesign = () => {
  const { t } = useTranslation();
  
  const metaTitle = "UI Design - Kalid M."
  const metaDescription = "Stunning user interface (UI) designs created with Figma and Adobe XD."
  const baseUrl = window.location.origin;
  const currentPath = window.location.pathname
  const canonicalUrl = `${baseUrl}${currentPath}`
  const ogImage = TBWCommingSoon;
  const ogImageAlt = "ui design"

  return (
    <div className="works-cont">
      <HelmetComponent 
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        canonicalUrl={canonicalUrl}
        ogImage={ogImage}
        ogImageAlt={ogImageAlt}
      />
      <div className='catagories-note-cont'>
          <img className='note-light-bulb' width="20" height="20" src={LightBulb} alt="light bulb" />   
          <p  className='catagories-note'> {t('note')} </p>
      </div>
      <div id="works" className="works">
        <UiDesignMap />
      </div>
    </div>
  )
}

export {UiDesign, UiDesignMap};