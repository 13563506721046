import React, { useState, useEffect } from "react";
import './styles/App.css';

import { MyContext } from "./MyContext";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import { Outlet, ScrollRestoration } from "react-router-dom";
import BackToTop from "./components/BackToTop";

function App() { 
  // --MediaQuery Check--
  const [isTabletMode, setIsTabletMode] = useState(false);
  const [isLargeScreenMode, setIsLargeScreenMode] = useState(false);
  
  useEffect(() => {
    const mediaQueryTabletMode = window.matchMedia('(min-width: 721px) and (max-width: 960px)');
    const mediaQueryLargeScreenMode = window.matchMedia('(min-width: 1281px)');   

        setIsTabletMode(mediaQueryTabletMode.matches);
        setIsLargeScreenMode(mediaQueryLargeScreenMode.matches);

        const handleChange = () => {
        setIsTabletMode(mediaQueryTabletMode.matches);
        setIsLargeScreenMode(mediaQueryLargeScreenMode.matches);

        };
        mediaQueryTabletMode.addEventListener('change', handleChange);
        mediaQueryLargeScreenMode.addEventListener('change', handleChange);

        return () => {
        mediaQueryTabletMode.removeEventListener('change', handleChange);
        mediaQueryLargeScreenMode.removeEventListener('change', handleChange);
        };
  }, []);
  
  return (
    <MyContext.Provider value={{isTabletMode, isLargeScreenMode}}>
      <div className="App">
        <ScrollRestoration />
        <NavBar />
        <Outlet />
        <Footer />
        <BackToTop />
      </div>
    </MyContext.Provider>
  );
}

export default App;