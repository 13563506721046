import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Blurhash } from 'react-blurhash';

const CatagoriesSkeleton = (props) => {
    const [isLoaded, setIsLoaded] = useState(false);
    
    const { t } = useTranslation();
    
    const handleImageLoad = () => {
            setIsLoaded(true)
        }
      
    return (
        <div key={props.index} className="work">
            <p className="work-title">{props.title}</p>
            <div className="work-img-cont" style={{height: props.height}}>
                <div className="work-blur-cont" style={{display: isLoaded === false ? "inline" : "none"}}>
                    <Blurhash 
                        hash={props.hash}
                        width={"100%"}
                        height={"100%"}
                        resolutionX={32}
                        resolutionY={32}  
                        punch={1}
                    />
                </div>
                <img
                    className="work-img"    
                    src={props.src} 
                    alt={props.title} 
                    style={{display: isLoaded === true ? "block" : "none"}}
                    onLoad={handleImageLoad}
                />
            </div>
            {(props.url && 
            <a className="view-work-btn" href={props.url} target="_blank" rel="noopener noreferrer"> 
                {t('visit_site')} <i class="bi bi-box-arrow-up-right"></i>
            </a>)
            }
        </div>
    )
}

export default CatagoriesSkeleton