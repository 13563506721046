import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, Outlet } from 'react-router-dom'
import HelmetComponent from '../components/HelmetComponent'
// --images--
import AsharaServices from '../asset/og/og-works.png'


const Works = () => {
    const { t } = useTranslation()
    const metaTitle = "Works - Kalid M."
    const metaDescription = "Static websites, landing pages, and dynamic web apps, along with technical seo and ui designs."
    const baseUrl = window.location.origin;
    const currentPath = window.location.pathname
    const canonicalUrl = `${baseUrl}${currentPath}`
    const ogImage = AsharaServices;
    const ogImageAlt = "works"
  return (
    <div className="catagories-container">
      <HelmetComponent 
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        canonicalUrl={canonicalUrl}
        ogImage={ogImage}
        ogImageAlt={ogImageAlt}
      />
      <div className="catagories">
          <h1 className="my-works">{ t('my_works') }</h1>
          <div className="catagories-link">
              <NavLink to={""} end> {t('all')} </NavLink>
              <NavLink to={"ui-design"}> {t('ui_design')} </NavLink>
              <NavLink to={"static-web-page"}> {t('landing_page')} </NavLink>
              <NavLink to={"react-app"}> {t('react_app')} </NavLink>
          </div>  

          
      </div>
      <Outlet/> 
    </div>
  )
}

export default Works