import { createBrowserRouter } from "react-router-dom";
// --custom componets--
import App from "./App";
// --pages--
import Home from './pages/Home';
import PageNotFound from './pages/PageNotFound';
import Works from './pages/Works';
// --nested pages--
import All from './pages/nested-pages/All';
import { UiDesign } from './pages/nested-pages/UiDesign';
import { LandingPage } from './pages/nested-pages/LandingPage';
import { ReactApp } from './pages/nested-pages/ReactApp';

export const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <PageNotFound />,
        children: [
            {
                path: "",
                element: <Home />
            },
            {
                path: "works/",
                element: <Works />,
                children: [
                    {
                    path: "",
                    element: 
                        <All />
                    },
                    {
                    path: "ui-design",
                    element: <UiDesign />
                    },
                    {
                    path: "static-web-page",
                    element: 
                        <LandingPage />
                    },
                    {
                    path: "react-app",
                    element: 
                        <ReactApp />
                    }
                ]
            }
        ]
    }
])