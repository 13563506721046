import React, {useState} from 'react'
import { NavLink } from 'react-router-dom'
import { Blurhash } from 'react-blurhash'
import { useTranslation } from 'react-i18next'
// custom components
import HelmetComponent from '../components/HelmetComponent'
import Contact from '../components/Contact'
import EmailForm from '../components/EmailForm'
// --images--
import Kalid from '../asset/Images/kalid.jpg'
import HtmlIcon from '../asset/icons/html5.png'
import CssIcon from '../asset/icons/css3.png'
import JsIcon from '../asset/icons/javascript.png'
import ReactIcon from '../asset/icons/react-js.png'
import XdIcon from '../asset/icons/adobe-xd.png'
import FigmaIcon from '../asset/icons/figma.png'
import Bootstrap from '../asset/icons/bootstrap.png'
import LogoSkyBlue from '../asset/og/og-home.png'

const Home = () => {
  const { t } = useTranslation()
  const [isLoaded, setIsLoaded] = useState(false);
  const [online] = useState(true)

  const handleImageLoad = () => {
    setIsLoaded(true)
  }

  const metaTitle = "Kalid Mohammed"
  const metaDescription = "I'm a front-end web developer. Mainly focused on HTML, CSS, JavaScript, React.js, Bootstrap, Adobe XD, and Figma. I'm now working as a freelancer."
  const baseUrl = window.location.origin;
  const currentPath = window.location.pathname
  const canonicalUrl = `${baseUrl}${currentPath}`
  const ogImage = LogoSkyBlue;
  const ogImageAlt = "kalid logo"

  return (
      <div id="home" className="home-container">
        <HelmetComponent 
          metaTitle={metaTitle}
          metaDescription={metaDescription}
          canonicalUrl={canonicalUrl}
          ogImage={ogImage}
          ogImageAlt={ogImageAlt}
        />
        <div className="pic-cont">
          <div className="blur-cont" style={{display: isLoaded === false ? "inline" : "none" }}>
            <Blurhash 
              hash="LDI;SA5R01Vr00My9Fe:lVrX_3sA"
              width={"100%"}
              height={"100%"}
              resolutionX={32}
              resolutionY={32}  
              punch={1}
            />
          </div>
          <img 
            style={{display: isLoaded === true ? "block" : "none"}}
            className="pic" 
            src={Kalid} 
            alt="kalid mohammed" 
            onLoad={handleImageLoad} 
          />
          <div className="pic-circle" ></div>
          {online && <div title="Online" className="online-status"></div>}
          
        </div>

        <div className="profile-info">
          <div className="name"> {t('name')} </div>
          <p className="description">
            {t('description')}
          </p>
          <NavLink className={"view-btn"} to={"/works"}> {t('view_my_works')}</NavLink>  
          
        </div>

        <div className="skills-container">
          <p className="skills-title"> {t('skills')} </p>
          <div className="skills">
            <img loading="lazy" src={HtmlIcon} alt="html" title="html"/>
            <img loading="lazy" src={CssIcon} alt="css" title="css" />
            <img loading="lazy" src={JsIcon} alt="javaScript" title="javaScript" />
            <img loading="lazy" src={Bootstrap} alt="bootstrap" title="bootstrap" />
            <img loading="lazy" src={ReactIcon} alt="react.js" title="react.js"/>
            <img loading="lazy" src={XdIcon} alt="adobe-xd" title="adobe-xd"/>
            <img loading="lazy" src={FigmaIcon} alt="figma" title="figma"/>
          </div>
        </div>

        <div className="contact-email-form-cont">
          <Contact />
          <div className="or">
            {t('or')}
          </div>
          <EmailForm />
        </div>
      </div>

  )
}

export default Home