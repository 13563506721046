import React from 'react'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
// --custom components--
import CatagoriesSkeleton from '../../components/CatagoriesSkeleton'
import { MyContext } from '../../MyContext'
import HelmetComponent from '../../components/HelmetComponent'
// --images--
import PortfolioUi from '../../asset/og/og-react-app.png'
import LightBulb from '../../asset/icons/light-bulb.png'
const ReactAppMap = () => {
  const { isTabletMode, isLargeScreenMode } = useContext(MyContext);
  const { t } = useTranslation()
  const reactApp = t('dynamic_web_app', {returnObjects: true})

  return (
    reactApp.map(({title, src, url, hash}, index) => (
    <CatagoriesSkeleton
      key={index}
      title={title}
      src={src}
      url={url}
      hash={hash}
      height={
        isTabletMode
            ? 133 
          : isLargeScreenMode 
            ? 196
          : 165}
    />
    ))
  )
}
const ReactApp = () => {
  const { t } = useTranslation();
  
  const metaTitle = "React App - Kalid M."
  const metaDescription = "Modern web apps built with react.js"
  const baseUrl = window.location.origin;
  const currentPath = window.location.pathname
  const canonicalUrl = `${baseUrl}${currentPath}`
  const ogImage = PortfolioUi;
  const ogImageAlt = "react app "

  return (  
    <div className="works-cont">
      <div className='catagories-note-cont'>
        <img className='note-light-bulb' width="20" height="20" src={LightBulb} alt="light bulb" />  
        <p  className='catagories-note'> { t('note') } </p>
      </div>

      <div id="works" className="works">
        <HelmetComponent 
          metaTitle={metaTitle}
          metaDescription={metaDescription}
          canonicalUrl={canonicalUrl}
          ogImage={ogImage}
          ogImageAlt={ogImageAlt}
        />

        <ReactAppMap />
      </div>
    </div>
  )
}

export {ReactApp, ReactAppMap};