import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';

export const EmailForm = () => {
  const form = useRef();
  const [isMessageSent, setIsMessageSent] = useState(false)
  const [user_email, setUser_email] = useState("")
  const [user_name, setUser_name] = useState("")
  const [user_message, setUser_message] = useState("")

  const { t } = useTranslation();

  const handleUserEmail = (e) => {
    setUser_email(e.target.value)
  }

  const handleUserName = (e) => {
    setUser_name(e.target.value)
  }

  const handleUserMessage = (e) => {
    setUser_message(e.target.value)
  }

  const sendEmail = (e) => {
    e.preventDefault();

    setIsMessageSent(true);

    emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_KEY)
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

    setUser_email("")
    setUser_name("")
    setUser_message("")
  };

  if(isMessageSent === true) {
    alert(t('form_alert'));
    setIsMessageSent(false)
  }

  return (
    <form className="reg-form" ref={form} onSubmit={sendEmail}>
      <div className="form-title">{t('leave_me_a_message')}</div>
      <div className="form-title-underline"></div>
      <label>{t('form_name')} *</label>
      <input 
        type="text" 
        name="user_name" 
        placeholder={t('form_name_placeholder')}
        onChange={handleUserName}
        value={user_name}
        required
      />
      <label>{t('form_email')} *</label>
      <input 
        type="email" 
        name="user_email" 
        placeholder={t('form_email_placeholder')}
        onChange={handleUserEmail}
        value={user_email}
        required
      />
      <label>{t('form_message')}</label>
      <textarea 
        name="message" 
        placeholder={t('form_message_placeholder')} 
        value={user_message}
        onChange={handleUserMessage}
      />
      <input 
        className="send-btn" 
        type="submit" 
        value={t('send')} />
    </form>
  );
};

export default EmailForm